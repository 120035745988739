import React, { useRef, useEffect } from "react";

export function maskInput(value, mask) {
   var literalPattern = /[0\*]/;
   var numberPattern = /[0-9]/;
   var newValue = "";
   try {
     var maskLength = mask.length;
     var valueIndex = 0;
     var maskIndex = 0;
 
     for (; maskIndex < maskLength;) {
       if (maskIndex >= value.length) break;
 
       if (mask[maskIndex] === "0" && value[valueIndex].match(numberPattern) === null) break;
 
       // Found a literal
       while (mask[maskIndex].match(literalPattern) === null) {
         if (value[valueIndex] === mask[maskIndex]) break;
         newValue += mask[maskIndex++];
       }
       newValue += value[valueIndex++];
       maskIndex++;
     }
 
   } catch (e) {
     console.log(e);
   }
   return newValue
 }


 export const getDate = (date) => {
   const [year, month, day] = date.split("-");
   return `${day}.${month}.${year}`;
 };


export function useDebounce(func, delay, cleanUp = false) {
  const timeoutRef = useRef();

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
}
import React from "react";
import s from "./DocumentContainerDownload.module.css";
import pdf from "../../assets/icons/pdf.svg";
import doc from "../../assets/icons/doc.svg";
import xls from "../../assets/icons/xls.svg";
import archive from "../../assets/icons/archive.svg";

const DocumentContainerDownload = ({
   document,
   documentName,
   title,
   text,
   date,
   format,
}) => {

   let isOldPage = null

   switch (title.trim()) {
      case "Решение от 06.02.2025 № 43/2":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5882&IID=24"
         break;

      case "Решение от 06.02.2025 № 43/3":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5883&IID=24"
         break;

      case "Решение от 06.02.2025 № 43/1":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5881&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/13":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5879&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/7":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5878&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/5":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5877&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/3":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5875&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/2":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5874&IID=24"
         break;

      case "Решение от 27.12.2024 № 41/2":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5800&IID=24"
         break;

      case "Решение от 19.12.2024 № 40/3":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5802&IID=24"
         break;

      case "Решение от 19.12.2024 № 40/2":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5801&IID=24"
         break;

      case "Решение от 28.11.2024 № 39/5":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5774&IID=24"
         break;

      case "Решение от 28.11.2024 № 39/4":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5773&IID=24"
         break;

      case "Решение от 27.01.2025 № 42/6":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5880&IID=2"
         break;

      case "Решение от 27.12.2024 № 41/1":
         isOldPage = "https://old.sdhimki.ru/documentation/index.php?ELEMENT_ID=5799&IID=24"
         break;

      default:
         break;
   }

   return (
      <div className={s.documentContainer}>
         <a
            target="_blank"
            rel="noopener noreferrer"
            download={documentName}
            href={isOldPage || document}
            className={s.link}
         >
            <div className={s.imgContainer}>
               {format?.toLowerCase().includes("doc") ? (
                  <img className={`${s.documentIcon} ${s.DOCX}`} src={doc} alt="" />
               ) : format?.toLowerCase().includes("xls") ? (
                  <img className={`${s.documentIcon} ${s.XLS}`} src={xls} alt="" />
               ) : format?.toLowerCase().includes("zip") ||
                  format?.toLowerCase().includes("rar") ? (
                  <img
                     className={`${s.documentIcon} ${s.ARCHIVE}`}
                     src={archive}
                     alt=""
                  />
               ) : (
                  <img className={`${s.documentIcon} ${s.PDF}`} src={pdf} alt="" />
               )}
            </div>
            <div className={s.textInner}>
               <div className={s.title}>{title}</div>
               <div className="mt4"></div>
               {text}
            </div>
         </a>
         {date && <div className={s.datePublication}>от {date}</div>}
      </div>
   );
};
export default DocumentContainerDownload;
